<template>
  <div class="inner-section application-form-wrapper">
      <bread-cumb />
      <!-- <div class="form-wrapper application-form-wrapper"> -->
          <body-card>
              <template v-slot:headerTitle>
                  <h4 v-if="!isRenew" class="card-title">{{ $t('lrcpnServicePanel.formalin_license_application') }}</h4>
                  <h4 v-else class="card-title">{{ $t('lrcpnServicePanel.formalin_license_application_renew') }}</h4>
              </template>
              <template v-slot:body>
                  <b-overlay :show="loading">
                  <form-wizard shape="circle" title="" subtitle="" :start-index.sync="activeIndex">
                      <tab-content :title="$t('lrcpnServicePanel.applicant_info')" icon="ri-file-cloud-line" :before-change="tabSubmitApplicantInfo">
                          <ApplicantForm :key="formData" :tabFormData="formData" ref="applicant_info_form" :status.sync="status" :is-renew="isRenew" :parent-id="formData.parent_id"/>
                      </tab-content>
                      <tab-content :title="$t('lrcpnServicePanel.business_info')" icon="ri-list-settings-line" :before-change="tabSubmitBusinessInfo">
                          <BusinessInfoForm :key="formData.business_info" :tabFormData="formData.business_info" ref="business_info_form" :status.sync="status" :is-renew="isRenew" />
                      </tab-content>
                      <template slot="footer" slot-scope="props">
                          <div class="wizard-footer-right">
                              <wizard-button class="btn btn-light ml-1 mr-1" @click.native="props.prevTab()" v-if="props.activeTabIndex > 0" style="background: #eee"><i class="ri-arrow-left-line"></i> {{ $t('globalTrans.back') }}</wizard-button>
                              <wizard-button
                                  type="submit"
                                  class="btn btn-primary ml-1 mr-1"
                                  title="Create Draft and Save!"
                                  @click.native="props.nextTab(), draft = 1, status = 1"
                                  v-if="showDraftButton"
                              ><i class="ri-draft-line"></i> {{$t('globalTrans.save_draft')}}
                              </wizard-button>
                              <wizard-button
                                  type="submit"
                                  v-if="!props.isLastStep"
                                  @click.native="props.nextTab(), status = 1"
                                  class="btn btn-success ml-1 mr-1"
                                  title="Draft and go to next!"
                                  :style="props.fillButtonStyle"
                              ><i class="ri-save-2-line"></i> {{ $t('priceMonitoring.save_next') }}
                              </wizard-button>
                              <wizard-button
                                  v-else
                                  type="submit"
                                  class="btn btn-success ml-1"
                                  title="Submit!"
                                  @click.native="finalSave(), status = 2"
                              ><i class="ri-send-plane-fill"></i> {{ $t('globalTrans.submit')}}
                              </wizard-button>
                              <wizard-button
                                  type="submit"
                                  v-if="props.isLastStep"
                                  @click.native="finalSave(), save_payment = 1, status = 2"
                                  class="btn btn-success ml-1 mr-1"
                                  title="Save and go to Payment!"
                                  :style="props.fillButtonStyle"
                              ><i class="ri-save-2-line"></i> {{ $t('dealer.save_and_payment') }}
                              </wizard-button>
                              <wizard-button
                                  type="button"
                                  class="btn btn-danger ml-1 mr-1"
                                  title="Cancel"
                                  @click.native="back()"
                              >
                                 <i class="ri-close-circle-line"></i> {{ $t('globalTrans.cancel') }}
                              </wizard-button>
                          </div>
                      </template>
                  </form-wizard>
                  </b-overlay>
              </template>
          </body-card>
      <!-- </div> -->
      <b-modal id="modal-payments" size="lg" :title="$t('lrcpnServicePanel.application_payment')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger" ref="payment-modal">
        <Payments :items="this.formData" :key="viewitemId" :is_tab="1"/>
     </b-modal>
  </div>
</template>
<script>
  import BreadCumb from '@/components/BreadCumb.vue'
  import { FormWizard, TabContent } from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import WizardButton from 'vue-form-wizard/src/components/WizardButton.vue'
  import ApplicantForm from './ApplicantForm.vue'
  import BusinessInfoForm from './BusinessInfoForm'
  import Payments from './Payment'
  import { applicationShowApi } from '../../api/routes'
  import RestApi, { lrcpnServiceBaseUrl } from '@/config/api_config'
  export default {
      components: { BreadCumb, FormWizard, TabContent, WizardButton, ApplicantForm, BusinessInfoForm, Payments },
      data () {
          return {
              app_id: this.$route.params.id,
              activeIndex: 0,
              baseUrl: lrcpnServiceBaseUrl,
              draft: 0,
              save_payment: 0,
              viewitemId: 0,
              formData: {
                  id: 0,
                  parent_id: 0,
                  applicant_name_en: '',
                  applicant_name_bn: '',
                  application_date: '',
                  father_spouse_name_en: '',
                  father_spouse_name_bn: '',
                  mother_name_en: '',
                  mother_name_bn: '',
                  applicant_nid: '',
                  same_as_permanent: 0,
                  permanent_area_type_id: 0,
                  permanent_division_id: 0,
                  permanent_district_id: 0,
                  permanent_city_corporation_id: 0,
                  permanent_upazila_id: 0,
                  permanent_pauroshoba_id: 0,
                  permanent_union_id: 0,
                  permanent_village_en: '',
                  permanent_village_bn: '',
                  present_area_type_id: 0,
                  present_division_id: 0,
                  present_district_id: 0,
                  present_city_corporation_id: 0,
                  present_upazila_id: 0,
                  present_pauroshoba_id: 0,
                  present_union_id: 0,
                  present_village_en: '',
                  present_village_bn: '',
                  telephone_number: '',
                  nationality: '',
                  business_info: {
                      id: 0,
                      app_main_id: this.$route.params.id ? this.$route.params.id : 0,
                      address_en: '',
                      address_bn: '',
                      telephone_number: '',
                      trade_license_info: {
                        trade_license_no: '',
                        trade_license_type: '',
                        trade_license_issue_place: '',
                        trade_license_issuing_authority: '',
                        trade_license_issue_date: '',
                        trade_license_expiry_date: ''
                      },
                      tax_info: {
                        zone: '',
                        circle: '',
                        tin_no: ''
                      },
                      tax_certificate_attachment: '',
                      warehouse_details: {
                        warehouse_cpacity: '',
                        warehouse_details: '',
                        warehouse_location: '',
                        warehouse_address_en: '',
                        warehouse_address_bn: ''
                      },
                      rationale_of_demand_attachment: '',
                      bank_lc_details: '',
                      import_amounts_info: [],
                      present_import_details: {
                        import_route: '',
                        import_purpose: '',
                        import_port_name: ''
                      },
                      have_previous_license: 0,
                      has_previous_license_rejected: 0,
                      previous_license_rejected_reasons: '',
                      previous_license_info: {
                        previous_license_number: '',
                        previous_license_provide_date: ''
                      },
                      import_helping_agent_name_en: '',
                      import_helping_agent_name_bn: '',
                      import_helping_agent_address: '',
                      have_any_case_formalin_act_2015: 0,
                      case_info: {
                        case_no_and_section: '',
                        date_of_case_judgment: '',
                        date_of_completion_of_sentence: ''
                      },
                      previous_import_formalin_attachment: '',
                      aditional_information: '',
                      applicant_signature: ''
                  }
              },
              status: 2,
              showDraftButton: true,
              isRenew: false
          }
      },
      created () {
          if (this.$route.params.id) {
              this.isLoading = true
              this.app_id = this.$route.params.id
              this.getFormData()
              this.isRenew = this.$route.params.isRenew
          } else {
              this.isLoading = false
          }
      },
      computed: {
          loading: function () {
              return this.$store.state.commonObj.loading
          }
      },
      watch: {
          activeIndex: function (newVal) {
              this.setDraftButtonStatus(newVal)
          }
      },
      methods: {
          setDraftButtonStatus (activeTabIndex) {
              if (activeTabIndex === 0) {
                  if (this.formData.id > 0 && this.formData.status === 1) {
                      this.showDraftButton = false
                  } else {
                      this.showDraftButton = true
                  }
              } else if (activeTabIndex === 1) {
                  if (this.formData.business_info.id > 0 && this.formData.business_info.status === 1) {
                      this.showDraftButton = false
                  } else {
                      this.showDraftButton = true
                  }
              } else {
                  this.showDraftButton = true
              }
          },
          finalSave () {
              this.$swal({
                  title: this.$t('globalTrans.final_save_msg'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('globalTrans.yes'),
                  cancelButtonText: this.$t('globalTrans.no'),
                  focusConfirm: false
              }).then((result) => {
                  if (result.isConfirmed) {
                      this.tabSubmitBusinessInfo()
                  } else {
                      this.app_status = 1
                  }
              })
          },
          async tabSubmitApplicantInfo () {
              const result = await this.$refs.applicant_info_form.submit()
              if (result.success) {
                  this.formData = Object.assign({}, this.formData, result.data)
                  if (!this.$route.params.id) {
                      this.formData.id = result.data.id
                    }
                  this.formData.business_info.app_main_id = result.data.id
                  if (this.draft || this.status === 2) {
                    this.$router.push({ name: 'lrcpn_service.formalin_import_management.license_application' })
                  } else {
                    return true
                  }
              }
          },
          async tabSubmitBusinessInfo () {
              const result = await this.$refs.business_info_form.submit()
              if (result.success) {
                    if (this.save_payment === 1) {
                        this.$refs['payment-modal'].show()
                    } else {
                        this.$router.push({ name: 'lrcpn_service.formalin_import_management.license_application' })
                    }
              }
          },
          async getFormData () {
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const result = await RestApi.getData(lrcpnServiceBaseUrl, applicationShowApi + '/' + this.app_id)
                if (result.success) {
                    if (result.data.business_info === null) {
                        result.data.business_info = Object.assign({}, this.formData.business_info, { app_main_id: result.data.id })
                    }
                    this.formData = Object.assign({}, this.formData, result.data)
                    if (this.isRenew) {
                        this.formData.application_date = ''
                    }
                    this.isLoading = false
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false })
            },
            back () {
                this.$router.go(-1)
            }
      }
  }
</script>
